import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css';

export class Layout extends Component {
  static displayName = Layout.name

  render() {
    return (
      <div>
        <Container>{this.props.children}</Container>
        <ToastContainer />
      </div>
    )
  }
}
